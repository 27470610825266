import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import {
  deleteVideoDetails,
  getVideoConfig,
  getAssetList,
  postVideoDetails as postVideoDetailsApi,
} from "Api/Pages/VideoListApi";
import uploadFile, {
  getResolutionConfig,
  getSizeConfig,
} from "Api/Pages/cloudinaryAssetUpload";
import {
  ASSET_TYPE_ENUM,
  IAssetDetails,
  UPLOAD_PURPOSE_ENUM,
  VideoConfigInterface,
} from "Components/Shared/UploadAsset/types";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { getVideoColumns } from "./constants";
import USE_QUERY_KEYS_CONSTANTS from "Api/useQueryKeyConstants";
import AssetLandingPage from "Components/Shared/AssetLandingPage";
import { AssetType } from "Components/Shared/AssetLandingPage/types";
import { INITIAL_PAGE } from "App/constants";
import { AssetTagsData } from "Interfaces/AssetTagsInterface";

const PromotionalVideos = () => {
  const navigate = useNavigate();

  // track progress for file upload
  const [uploadProgressPercentage, setUploadProgressPercentage] =
    useState<number>(0);
  // Video details upload modal
  const [isModalOpen, setModalVisibility] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);

  // api calls
  const { data: assetConfig } = useQuery<VideoConfigInterface>(
    USE_QUERY_KEYS_CONSTANTS.videoConfigs,
    () => getVideoConfig(),
    {
      staleTime: 1000 * 60, // 1 minute
    }
  );

  // toggle video details upload modal
  const toggleVideoUploadModal = () => {
    setModalVisibility((isOpen) => !isOpen);
  };

  const { data: result, refetch: fetchPromotionalVideos } = useQuery(
    USE_QUERY_KEYS_CONSTANTS.promotionalVideosList,
    () =>
      getAssetList({
        currentPage: currentPage,
        per_page: 10,
        upload_purpose: UPLOAD_PURPOSE_ENUM.featured,
        of_type: ASSET_TYPE_ENUM.video,
      }),
    { refetchOnWindowFocus: false, enabled: false }
  );

  const onRowClick = (rowData: AssetType) => {
    // navigate to details page/modal
    navigate(`/featured-videos/${rowData.id}`, { state: rowData });
  };

  const onDeleteRowClicked = async (selectedId: number) => {
    await deleteVideoDetails(selectedId);
    // On successful delete fetch featured videos
    fetchPromotionalVideos();
  };

  const onVideoUploadProgressCallback = (
    progressEvent: any,
    totalFileSize: number
  ) => {
    setUploadProgressPercentage(
      Math.round((progressEvent.loaded / totalFileSize) * 100)
    );
  };

  const onVideoSaveCallback = (
    videoDetails: IAssetDetails,
    assetTagsData: AssetTagsData
  ) => {
    // file upload start flag set and show progress bar
    const { title, description, asset } = videoDetails;

    // upload file
    if (asset !== null && assetConfig?.cloudinary) {
      const fileUpload = asset[0];
      try {
        uploadFile(
          fileUpload,
          {
            ofType: ASSET_TYPE_ENUM.video,
            cloudName: assetConfig?.cloudinary.cloud_name,
            apiKey: assetConfig?.cloudinary.api_key,
            purpose: UPLOAD_PURPOSE_ENUM.featured,
          },
          (progressEvent) =>
            onVideoUploadProgressCallback(progressEvent, fileUpload.size)
        ).then(async (response: any) => {
          toggleVideoUploadModal();
          setUploadProgressPercentage(0);

          await postVideoDetailsApi({
            title,
            description,
            of_type: ASSET_TYPE_ENUM.video,
            upload_purpose: UPLOAD_PURPOSE_ENUM.featured,
            url: response.data.secure_url,
            ...assetTagsData,
          });

          // once complete save details to sport skill api server
          // after saving details to api server call listing api
          fetchPromotionalVideos();
        });
      } catch (error) {
        setUploadProgressPercentage(0);

        // show error clear selected file and ask user to reupload
      }
    } else {
      console.error("Asset missing / config missing to upload asset");
    }
  };

  const handleVideoSubmissionNavigate = () => {
    navigate("/featured-videos/submissions");
  };

  useEffect(() => {
    //fetch featured videos from server
    fetchPromotionalVideos();
  }, [fetchPromotionalVideos, currentPage]);

  return (
    <AssetLandingPage
      uploadModalProps={{
        purpose: UPLOAD_PURPOSE_ENUM.featured,
      }}
      title="Featured Videos"
      subActionButtonProps={{
        label: "Video Submissions",
        onClickHandler: handleVideoSubmissionNavigate,
      }}
      actionButtonProps={{
        label: "Upload Video",
        icon: faPlus,
        onClickHandler: toggleVideoUploadModal,
      }}
      tableProps={{
        columns: getVideoColumns(onDeleteRowClicked),
        tableData: result?.data || [],
        noDataMessage: "Please upload featured videos",
        onRowClick,
        clickable: true,
        paginationProps: {
          totalCount: result?.total_count || 0,
          currentPage: currentPage,
          numberOfRowPerPage: 10,
          setPageNumber: setCurrentPage,
        },
      }}
      isUploadModalOpen={isModalOpen}
      uploadProgressPercentage={uploadProgressPercentage}
      allowedFileFormats={assetConfig?.video?.allowed_file_formats || []}
      sizeConfig={getSizeConfig(assetConfig)}
      resolutionConfig={getResolutionConfig(assetConfig)}
      toggleVideoUploadModal={toggleVideoUploadModal}
      onVideoSaveCallback={onVideoSaveCallback}
    />
  );
};

export default PromotionalVideos;
