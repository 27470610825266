import { useCallback, useRef, useState } from "react";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "Components/Core";
import { ButtonIcon } from "Components/Shared";
import { bulkUpload, getDownloadTemplate } from "Api/BulkUploadApi";
import { HeaderComponentPropsType } from "Interfaces/Components/Shared/HeaderInterface";
import {
  BULKUPLOAD_GUIDELINES,
  DOWNLOAD_REPORT,
  DOWNLOAD_TEMPLATE,
  EMAIL_REPORT,
  ReportModalType,
} from "./constants";
import {
  ACCEPTED_EXTENSION_FOR_BULK_UPLOAD,
  BULK_UPLOAD_BUTTON_TITLE,
} from "App/constants";
import { history } from "App";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { setConfirmProps } from "App/commonReducer";
import ReportModal from "./ReportModal";

const HeaderComponent = (props: HeaderComponentPropsType) => {
  const {
      title,
      buttonProps,
      shouldShowNav = true,
      shouldShowBulkUpload,
    } = props,
    {
      userDetails: { academy_id },
    } = useAppSelector((state) => state.loginReducer),
    { confirmProps } = useAppSelector((state) => state.commonReducer),
    dispatch = useAppDispatch(),
    fileRef = useRef<HTMLInputElement>(null);

  const [showReportModal, setShowReportModal] = useState(false);
  const [reportModalType, setReportModalType] = useState<ReportModalType>(
    ReportModalType.DOWNLOAD
  );

  const handleBulkUploadChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { files },
      } = event;
      if (files) {
        const formData = new FormData();
        formData.append("file", files[0]);
        await bulkUpload({
          title: title,
          formData: formData,
          academyId: academy_id,
        });
      }
    },
    [title, academy_id]
  );

  const handleBulkUploadButtonClick = () => {
    dispatch(
      setConfirmProps({
        ...confirmProps,
        modalType: "Guidelines",
        modalClassName: "guidelines-modal",
        confirmBodyProps: {
          list: BULKUPLOAD_GUIDELINES,
        },
        buttonProps: [
          { title: "Cancel" },
          {
            title: "Continue",
            onClick: () => {
              fileRef.current?.click();
            },
          },
        ],
        showConfirm: true,
      })
    );
  };

  const handleDownloadTemplate = async () => {
    const downloadLink = await getDownloadTemplate(academy_id);
    const element = document.createElement("a");
    element.setAttribute("href", downloadLink);
    element.setAttribute("download", `download_template.csv`);
    element.setAttribute("rel", "noopener noreferrer");
    element.click();
  };

  const handleDownloadReport = async () => {
    setShowReportModal(true);
    setReportModalType(ReportModalType.DOWNLOAD);
  };

  const handleEmailReport = async () => {
    setShowReportModal(true);
    setReportModalType(ReportModalType.EMAIL);
  };

  return (
    <>
      <header className="ss-page-header d-flex align-items-center justify-content-between px-4">
        <div className="d-flex align-items-center gap-2">
          {shouldShowNav && (
            <FontAwesomeIcon
              icon={faChevronLeft}
              type="button"
              onClick={history.back}
            />
          )}
          <h2 className="fs-md mb-0 fw-medium">{title}</h2>
        </div>
        {buttonProps && (
          <div className="d-flex align-items-center gap-2">
            {shouldShowBulkUpload && (
              <>
                <Button
                  variant="outline-secondary"
                  onClick={handleDownloadReport}
                >
                  {DOWNLOAD_REPORT}
                </Button>
                <Button variant="outline-secondary" onClick={handleEmailReport}>
                  {EMAIL_REPORT}
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleDownloadTemplate}
                >
                  {DOWNLOAD_TEMPLATE}
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleBulkUploadButtonClick}
                >
                  {BULK_UPLOAD_BUTTON_TITLE[title]}
                </Button>
                <input
                  ref={fileRef}
                  onChange={handleBulkUploadChange}
                  multiple={false}
                  type="file"
                  accept={ACCEPTED_EXTENSION_FOR_BULK_UPLOAD}
                  hidden
                />
              </>
            )}
            <ButtonIcon
              className="btn-outline-secondary fs-sm"
              onClick={buttonProps.onClick}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              {buttonProps.buttonText}
            </ButtonIcon>
          </div>
        )}
      </header>
      <hr className="ss-page-seprator mx-4 my-0" />
      <ReportModal
        show={showReportModal}
        reportType={reportModalType}
        onCloseModal={() => setShowReportModal(false)}
      />
    </>
  );
};

export default HeaderComponent;
