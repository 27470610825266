import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "Components/Core";
import {
  SearchControl,
  TableComponent,
  PaginationControl,
  HeaderComponent,
  SelectSports,
} from "Components/Shared";
import { resetState } from "Components/Shared/SurveyHistory/surveyHistoryReducer";
import { cancelBatch } from "Api/Pages/BatchesApi";
import { deleteCoach } from "Api/Pages/CoachesApi";
import { deletePlayer } from "Api/Pages/PlayersApi";
import { getList } from "Api/Components/DisplayListComponentApi";
import { DisplayListComponentPropType } from "Interfaces/Components/Shared/DisplayListComponentInterface";
import { SortColumnType } from "Interfaces/Pages/BatchesInterface";
import { UserDetailsObjectType } from "Interfaces/Components/Shared/UserDetailsFormInterface";
import {
  ACTIVE,
  BUTTONT_TEXT,
  CONFIRMATION_MESSAGE,
  FILTERED_LIST,
  FORM_CONSTANT,
  getColumns,
  HEADER_TITLE,
  ON_ADD_NAVIGATE_ROUTE,
  ON_EDIT_NAVIGATE_ROUTE,
  ON_ROWCLICK,
  TOTAL_MATCH_PLAYED,
} from "./constants";
import { COACH_WARNING } from "Pages/Coaches/constants";
import { SORT_COLUMN, ASC, DESC } from "Pages/Batches/constants";
import {
  BATCH,
  COACH,
  PLAYER,
  SPORT,
  CONFIRM,
  INITIAL_PAGE,
  RECORDS_PER_PAGE,
  removeComfirmMessage,
  TEXT_END,
} from "App/constants";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { setConfirmProps } from "App/commonReducer";

const DisplayListComponent = (props: DisplayListComponentPropType) => {
  const { title, isMatchPlayed, matchPlayedUserType, id } = props;
  const dispatch = useAppDispatch(),
    navigate = useNavigate(),
    [filteredList, setFilteredList] = useState<any>([]),
    [searchInput, setSearchInput] = useState<string>(""),
    [selectValue, setSelectValue] = useState<string>(""),
    [active, setActive] = useState<string>(ACTIVE),
    [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE),
    [totalPages, setTotalPages] = useState<number>(INITIAL_PAGE),
    [totalMatchPlayed, setTotalMatchPlayed] = useState<number>(),
    [filterConfig, setFilterConfig] = useState<SortColumnType>(SORT_COLUMN),
    { confirmProps } = useAppSelector((state) => state.commonReducer),
    { Confirm, TextCenter, TextEnd, Ok } = FORM_CONSTANT,
    {
      userDetails: { academy_id, permissions },
    } = useAppSelector((state) => state.loginReducer);

  const fetchList = useCallback(async () => {
    const { latestClicked } = filterConfig;
    if (!(!selectValue && isMatchPlayed)) {
      const list = await getList({
          userType: title,
          academyId: academy_id,
          searchInput: searchInput,
          selectValue: selectValue,
          currentPage: currentPage,
          active: active,
          sort_column: latestClicked,
          order_by: filterConfig[latestClicked],
          matchPlayedUserType: matchPlayedUserType,
          id: id,
        }),
        { total_count } = list,
        data = list[FILTERED_LIST[title]],
        numOfPages = Math.ceil(total_count / RECORDS_PER_PAGE);
      setTotalMatchPlayed(total_count);
      setTotalPages(numOfPages);
      setFilteredList(data);
    }
  }, [
    academy_id,
    searchInput,
    selectValue,
    currentPage,
    title,
    active,
    filterConfig,
    matchPlayedUserType,
    id,
    isMatchPlayed,
  ]);

  const handleSearch = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
          target: { value },
        } = event;
        setSearchInput(value);
        setCurrentPage(INITIAL_PAGE);
      },
      [setSearchInput, setCurrentPage]
    ),
    handleSelect = useCallback(
      (event: React.ChangeEvent<HTMLSelectElement>) => {
        const {
          target: { value },
        } = event;
        setSelectValue(value);
        setCurrentPage(INITIAL_PAGE);
      },
      [setSelectValue, setCurrentPage]
    ),
    handleSubmit = useCallback((event: React.ChangeEvent<HTMLFormElement>) => {
      event.preventDefault();
    }, []),
    handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
          target: { checked },
        } = event;
        checked ? setActive(ACTIVE) : setActive("");
      },
      [setActive]
    ),
    onApplyFilter = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const column = event.currentTarget.getAttribute("data-column");
        column &&
          setFilterConfig({
            ...filterConfig,
            latestClicked: column,
            [column]: filterConfig[column] === ASC ? DESC : ASC,
          });
      },
      [filterConfig]
    ),
    onCancelBatch = useCallback(
      (id: number) => {
        dispatch(
          setConfirmProps({
            ...confirmProps,
            buttonProps: [{ title: CONFIRM, onClick: () => cancelBatch(id) }],
            confirmBodyProps: {
              message: [`${removeComfirmMessage} ${BATCH}?`],
            },
            closeButtonClassName: TEXT_END,
            modalType: CONFIRM,
            showConfirm: true,
          })
        );
      },
      [confirmProps, dispatch]
    ),
    onConfirmDelete = useCallback(
      async (id: number) => {
        if (title === COACH) {
          const { assigned_batches_names } = await deleteCoach({
            academyId: academy_id,
            id,
          });
          if (assigned_batches_names) {
            dispatch(
              setConfirmProps({
                ...confirmProps,
                closeButtonClassName: TextCenter,
                modalType: CONFIRM,
                confirmBodyProps: {
                  message: COACH_WARNING,
                  list: assigned_batches_names,
                },
                buttonProps: [{ title: Ok, onClick: () => {} }],
                showConfirm: true,
              })
            );
          }
        } else if (title === PLAYER) {
          await deletePlayer({ academyId: academy_id, id });
        }
        fetchList();
      },
      [confirmProps, Ok, TextCenter, academy_id, dispatch, fetchList, title]
    ),
    onDelete = useCallback(
      (id: number) => {
        dispatch(
          setConfirmProps({
            ...confirmProps,
            closeButtonClassName: TextEnd,
            modalType: CONFIRM,
            confirmBodyProps: {
              message: [CONFIRMATION_MESSAGE[title]],
            },
            buttonProps: [
              { title: Confirm, onClick: () => onConfirmDelete(id) },
            ],
            showConfirm: true,
          })
        );
      },
      [TextEnd, title, Confirm, confirmProps, onConfirmDelete, dispatch]
    ),
    onAdd = useCallback(() => {
      navigate(ON_ADD_NAVIGATE_ROUTE[title], {
        state: { isEditUser: false, userType: title },
      });
    }, [navigate, title]),
    onEdit = useCallback(
      (id: number) => {
        navigate(ON_EDIT_NAVIGATE_ROUTE[title](id), {
          state: { isEditUser: true, userType: title },
        });
      },
      [navigate, title]
    ),
    onRowClick = useCallback(
      (props: UserDetailsObjectType) => {
        dispatch(resetState());
        props.id && navigate(ON_ROWCLICK[title](props.id));
      },
      [dispatch, navigate, title]
    );

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      {!isMatchPlayed && (
        <HeaderComponent
          title={HEADER_TITLE[title]}
          shouldShowNav={false}
          shouldShowBulkUpload={title === PLAYER ? true : false}
          buttonProps={{
            buttonText: BUTTONT_TEXT[title],
            onClick: onAdd,
          }}
        />
      )}

      <Form
        className="d-flex align-items-start justify-content-between mb-4 px-4"
        onSubmit={handleSubmit}
      >
        <div className="d-flex justify-content-center gap-3 flex-column">
          <SelectSports
            handleSelect={handleSelect}
            shouldShowAll={!isMatchPlayed}
            selected={selectValue}
            shouldPassMasterSportId={isMatchPlayed}
            title={SPORT}
          />
          {isMatchPlayed && (
            <p className="d-flex align-items-center gap-1 mb-0 mt-0">
              {TOTAL_MATCH_PLAYED}
              <span className="fw-bold fs-4">{totalMatchPlayed}</span>
            </p>
          )}
        </div>
        <SearchControl
          className="mt-4"
          placeholder="Search"
          label="Search"
          onChange={handleSearch}
        />
      </Form>
      <div className="flex-1 scroll-y px-4 mb-3">
        {title === BATCH && (
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Active Only"
            className="custom-switch-check mb-3"
            defaultChecked={true}
            onChange={handleChange}
          />
        )}
        <div className="table-responsive">
          {filteredList && (
            <TableComponent
              columns={getColumns[title]({
                onEdit: onEdit,
                onDelete: onDelete,
                permissions: permissions,
                onApplyFilter: onApplyFilter,
                onCancelBatch: onCancelBatch,
                filterConfig: filterConfig,
              })}
              tableData={filteredList}
              onRowClick={onRowClick}
              isClickAble={!isMatchPlayed}
            />
          )}
        </div>
      </div>
      <div className="pagination-wrap d-flex justify-content-end px-4">
        <PaginationControl
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};
export default DisplayListComponent;
