import { useNavigate, useParams } from "react-router-dom";

import AssetVideoDetail from "Components/Shared/AssetVideoDetail";

const DrillVideoDetail = () => {
  const params = useParams();
  const navigate = useNavigate();

  const id = Number(params.id);

  if (Number.isNaN(id)) {
    navigate("/page-not-found");
  }

  const handleBackNavigation = () => {
    navigate("/drills");
  };

  return <AssetVideoDetail videoId={id} onBackClick={handleBackNavigation} />;
};

export default DrillVideoDetail;
